import 'owl.carousel2';

$(document).ready(function(){
    $('.list-images._mobile').owlCarousel({
      items: 1,
      margin: 15
    });
    $('.home-slider').owlCarousel({
      dots: true,
      items: 1,
      autoplay: true
    })
});